<template>
  <!-- 排班管理 -->
  <div ref="workforceManagement" class="workforceManagement">
    <!-- 操作栏 -->
    <div class="ActionBar" ref="ActionBar">
      <div class="actionBar-box">
        <div class="col-1">起点站：</div>
        <div class="col-2">
          <el-select
            v-model="formData.startStationList"
            multiple
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in startSelect"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">终点站：</div>
        <div class="col-2">
          <el-select
            v-model="formData.endStationList"
            multiple
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in endSelect"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="actionBar-box">
        <div class="col-1">发车日期起：</div>
        <div class="col-2">
          <el-date-picker
            v-model="formData.startTime"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">发车日期止：</div>
        <div class="col-2">
          <el-date-picker
            v-model="formData.endTime"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">所属公司：</div>
        <div class="col-2">
          <companySelect
            ref="companySelect"
            :clearable="true"
            :company-tree="companyList"
            :valuename="formData.companyName"
            :value="formData.companyId"
            @getValue="getAddGroupId"
          ></companySelect>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">类型：</div>
        <div class="col-2">
          <el-select v-model="formData.type" clearable placeholder="请选择">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="actionBar-box">
        <div class="col-1">车次：</div>
        <div class="col-2">
          <el-input v-model="formData.vehicleShiftName" clearable></el-input>
        </div>
      </div> -->
      <div class="actionBar-box">
        <span
          v-for="item in timeBtnObj"
          :key="item.id"
          :class="
            item.id == timeBtnActive ? 'timeBtn timeBtnActive' : 'timeBtn'
          "
          @click="timeBtnFun(item.id)"
          >{{ item.label }}</span
        >
      </div>
      <div class="actionBar-box">
        <el-button
          :disabled="queryBtnDisabled"
          type="primary"
          size="small"
          @click="search"
          >查询</el-button
        >
        <el-button
          v-if="listFind('生成排班')"
          type="primary"
          size="small"
          @click="popupFun(3)"
          >生成排班</el-button
        >
      </div>
    </div>
    <el-tabs type="border-card" @tab-click="handleClick">
      <el-tab-pane label="排班记录">
        <Table
          :table-data="tableData"
          :title-name="titleName"
          :operation="showOprateBtn"
          :col-class-type="true"
          operation-width="180"
          :table-height="TableHeight"
        >
          <template slot-scope="scope">
            <div v-if="scope.scopeRow.status == 1 ? false : true">
              <template v-if="listFind('暂停')">
                <el-button
                  v-if="scope.scopeRow.status == 2 ? false : true"
                  :class="
                    scope.scopeRow.ticketNum == scope.scopeRow.surplusTicket
                      ? ''
                      : 'prohibit'
                  "
                  type="text"
                  size="small"
                  sort="primary"
                  @click="optionBtnFun(scope.scopeRow, 1)"
                  >暂停</el-button
                >
              </template>
              <template v-if="listFind('改班')">
                <el-button
                  v-if="scope.scopeRow.status == 2 ? false : true"
                  :class="
                    scope.scopeRow.ticketNum == scope.scopeRow.surplusTicket
                      ? ''
                      : 'prohibit'
                  "
                  type="text"
                  size="small"
                  sort="primary"
                  @click="optionBtnFun(scope.scopeRow, 2)"
                  >改班</el-button
                >
              </template>
              <template v-if="listFind('撤班')">
                <el-button
                  v-if="scope.scopeRow.status == 2 ? false : true"
                  :class="
                    scope.scopeRow.ticketNum == scope.scopeRow.surplusTicket
                      ? ''
                      : 'prohibit'
                  "
                  type="text"
                  size="small"
                  sort="primary"
                  @click="optionBtnFun(scope.scopeRow, 3)"
                  >撤班</el-button
                >
              </template>
              <!-- 9.0不要预留 -->
              <!-- <template v-if="listFind('预留')">
                <el-button
                  v-if="reservedAble(scope.scopeRow)"
                  type="text"
                  size="small"
                  sort="primary"
                  @click="optionBtnFun(scope.scopeRow, 6)"
                  >预留</el-button
                >
                <el-button
                  v-else
                  type="text"
                  size="small"
                  sort="primary"
                  class="prohibit"
                  >预留</el-button
                >
              </template> -->
              <template v-if="listFind('恢复')">
                <el-button
                  v-if="scope.scopeRow.status == 2 ? true : false"
                  type="text"
                  size="small"
                  sort="primary"
                  @click="optionBtnFun(scope.scopeRow, 4)"
                  >恢复</el-button
                >
              </template>
            </div>
          </template>
        </Table>
        <div ref="pagination" class="pagination">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-size="formData.pageSize"
            :current-page="formData.currentPage"
            @size-change="onSizeChange"
            @current-change="onCurrentChange"
          ></el-pagination>
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="排班计划">
        <div class>
          <el-button
            style="margin-bottom:10px;"
            type="primary"
            size="small"
            @click="popupFun(2)"
          >添加计划</el-button>
          <Table
            :operation="true"
            :col-class-type="true"
            operation-width="150"
            :table-data="tableData2"
            :title-name="titleName2"
            :table-height="TableHeight"
          >
            <template slot-scope="scope">
              <div v-if="scope.scopeRow.status == 1?false:true">
                <el-button
                  type="text"
                  size="small"
                  sort="primary"
                  @click="popupFun(2,'query',scope.scopeRow)"
                >查看</el-button>
                <el-button
                  type="text"
                  size="small"
                  sort="primary"
                  @click="popupFun(2,'edit',scope.scopeRow)"
                >编辑</el-button>
                <el-button
                  type="text"
                  size="small"
                  sort="primary"
                  @click="deleteLineFun(scope.scopeRow)"
                >删除</el-button>
              </div>
            </template>
          </Table>
        </div>
      </el-tab-pane>-->
    </el-tabs>
    <!-- 公用弹窗组件 -->
    <Popup
      v-if="workPopupShow"
      ref="popForm"
      :popup-num="popupNum"
      :scheduling-plan-o-b-j="SchedulingPlanOBJ"
      @closePopupFun="closePopupFun"
      @defineFun="popupDefineFun"
      @saveOk="workPopOk"
    />
  </div>
</template>

<script>
import Popup from "./workforcePopup/index.vue";
import companySelect from "@/components/treeSelect/companySelect.vue";

import {
  intercityPageAPI,
  startStationAPI,
  endStationAPI,
  shiftIntercityAPI,
  scheduledRuleListAPI,
  deleteScheduledRuleAPI,
  getCompanyTree,
} from "@/api/lib/api.js";
import moment from "moment";
export default {
  components: { Popup, companySelect },
  data () {
    return {
      current: new Date(),
      SchedulingPlanOBJ: {},
      queryBtnDisabled: false,
      startSelect: [], // 起点站下拉数据
      endSelect: [], // 终点站下拉数据
      total: 0,
      formData: {
        type: "",
        startTime: "",
        endTime: "",
        startStationList: [],
        endStationList: [],
        currentPage: 1,
        pageSize: 10,
      },
      typeOptions: [
        { value: 0, label: "自动开班" },
        { value: 1, label: "不自动开班" },
        { value: 2, label: "改班" },
        { value: 3, label: "停班" },
      ],
      tableData2: [],
      titleName2: [
        {
          title: "生成计划线路",
          props: "lineName",
        },
        {
          title: "计划执行时间",
          props: "executionTime",
        },
        {
          title: "生成排班天数",
          props: "executionDay",
        },
        {
          title: "覆盖相同班次",
          props: "isCover",
          SpecialJudgment: (res) => {
            return res == 0 ? "不覆盖" : res == 1 ? "覆盖" : "";
          },
        },
        {
          title: "计划生效",
          props: "isEffective",
          SpecialJudgment: (res) => {
            return res == 0 ? "无效" : res == 1 ? "有效" : "";
          },
        },
      ],
      tableData: [],
      titleName: [
        // {
        //   title: "线路",
        //   props: "lineName",
        //   width: 100,
        // },
        // {
        //   title: "车次",
        //   props: "vehicleShiftName",
        // },
        {
          title: "班次日期",
          props: "departureDate",
          width: 160,
          SpecialJudgment: (res) => {
            return res.substr(0, 10);
          },
        },
        {
          title: "班次时间",
          // props: "departureTime",
          width:'120px',
          SpecialJudgment: (res,index) => {
            let timeStr = res.startDepartureTime;
            //定班只显示开始时间
            if(res.shiftType === 1) {
              timeStr += '-' + res.endDepartureTime;
            }
            return timeStr;
          },
        },
        {
          title: "线路",
          // props: "lineName",//主线路
          props: "",
          render: (h, params, index) => {
            return h("span", `${params.startStation}-${params.endStation}`);
          },
          width: 100,
        },
        {
          title: "状态",
          props: "status",
          SpecialJudgment: (res) => {
            return res == 0
              ? "正常"
              : res == 1
                ? "停班"
                : res == 2
                  ? "停售"
                  : "";
          },
        },
        {
          title: "车辆等级",
          props: "vehicleLevel",
          SpecialJudgment: (res) => {
            return res == 1
              ? "小巴"
              : res == 2
                ? "中巴"
                : res == 3
                  ? "大巴"
                  : "";
          },
        },
        {
          title: "票位",
          props: "ticketNum",
        },
        {
          title: "已售",
          props: "soldTicket",
        },
        {
          title: "预留",
          props: "reservedTicket",
        },
        {
          title: "起点站余票",
          props: "surplusTicket",
        },
        {
          title: "类型",
          props: "type",
          SpecialJudgment: (res) => {
            return res == 0
              ? "自动开班"
              : res == 1
                ? "不自动开班"
                : res == 2
                  ? "改班"
                  : res == 3
                    ? "停班"
                    : "";
          },
        },
        {
          title: "所属公司",
          props: "companyName",
          width: 100,
        },
        {
          title: "最后生成时间",
          props: "updateTime",
          width: 160,
        },
        {
          title: "最后生成人",
          props: "operatorUser",
          width: 120,
        },
        {
          title: "备注",
          props: "remark",
        },
      ],
      workPopupShow: false, // 弹窗显示变量
      ids: "",
      popupNum: undefined, // 弹窗传递num值
      TableHeight: 0,
      timeBtnObj: [
        {
          label: "今天",
          id: 0,
        },
        {
          label: "明天",
          id: 1,
        },
        {
          label: "未来三天内",
          id: 2,
        },
        {
          label: "未来七天内",
          id: 3,
        },
      ],
      timeBtnActive: null,
      companyList: [],
      showOprateBtn: true,
    };
  },
  watch: {
    '$route.path' (val, oldVal) {
      this.isShowOprate(val);
    }
  },
  mounted () {
    this.isShowOprate(this.$route.path);
    this.selectdataAPIFun();
    this.getCompanyList();
    this.renderData();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    //判断路由根据路由展示操作项
    isShowOprate (val) {
      if (val && val === '/projectTravel/schedulingManagement') {
        this.showOprateBtn = false;
      } else {
        this.showOprateBtn = true;
      }
    },
    // 获取企业树
    getCompanyList () {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    //选择公司的回调
    getAddGroupId (companyId) {
      this.formData.companyId = companyId;
      this.formData.companyIds = companyId ? [companyId] : null;
      console.log(this.formData);
    },
    // 计算表格高度
    computeHeight () {
      const ActionBarHeight = this.$refs.ActionBar.clientHeight;
      const workforceManagement = document.documentElement.clientHeight;
      this.TableHeight = `${workforceManagement - (ActionBarHeight + 280)}px`;
    },
    // 删除排班计划
    deleteLineFun (scope) {
      deleteScheduledRuleAPI({ id: scope.id }).then((res) => {
        if (res.code == "SUCCESS") {
          this.renderData2();
        }
      });
    },
    // 判断是否可预留
    reservedAble (row) {
      if (row.status == 2) return false;
      if (row.surplusTicket == 0) return false;
      const deprat =
        row.departureDate.split(" ")[0] + " " + row.departureTime + ":00";
      if (this.current > new Date(deprat)) return false;
      return true;
    },
    // 关闭弹窗callback
    closePopupFun (val, num) {
      this.workPopupShow = val;
      this.SchedulingPlanOBJ = {
        status: "",
        scope: {},
      };
      if (num == 2) {
        this.renderData2();
      } else if (num == 3) {
        this.formData.currentPage = 1;
        this.renderData();
      }
    },
    handleClick (tab) {
      if (tab.label == "排班记录") {
        this.renderData();
        this.queryBtnDisabled = false;
      } else {
        // 否则就是排班计划
        this.renderData2();
        this.queryBtnDisabled = true;
      }
    },
    renderData2 () {
      scheduledRuleListAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData2 = res.data;
        }
      });
    },
    // 弹窗保存按钮callback
    popupDefineFun (obj) {
      switch (obj.num) {
        case 4:
          if (obj.departureTime == "") {
            this.$message.error("请选择班次时间");
          } else {
            this.shiftIntercityAPIFun({
              id: this.ids,
              remark: obj.remark,
              departureTime: obj.departureTime,
              operation: 0,
            });
          }
          break;
        // 撤班确定
        case 5:
          this.shiftIntercityAPIFun({
            id: this.ids,
            remark: obj.remark,
            operation: 1,
          });
          break;
        // 预留
        case 6:
          this.shiftIntercityAPIFun({
            operation: 4,
            id: this.ids,
            remark: obj.remark,
            isAdd: obj.isAdd,
            reservedTicket: obj.reservedTicket,
          });
          break;
      }
    },
    //预留成功
    workPopOk () {
      this.workPopupShow = false;
      this.renderData();
    },
    // 点击操作栏按钮的时候
    optionBtnFun (scope, val) {
      if (scope.ticketNum == scope.surplusTicket) {
        switch (val) {
          // 暂停
          case 1:
            this.shiftIntercityAPIFun({ id: scope.id, operation: 2 });
            break;
          // 改班
          case 2:
            this.popupNum = 4;
            this.workPopupShow = true;
            this.ids = scope.id;
            break;
          // 撤班
          case 3:
            this.popupNum = 5;
            this.workPopupShow = true;
            this.ids = scope.id;
            break;
          // 恢复
          case 4:
            this.shiftIntercityAPIFun({ id: scope.id, operation: 3 });
            break;
          // 预留
          case 6:
            this.popupNum = 6;
            this.workPopupShow = true;
            this.$nextTick(() => {
              this.$refs.popForm?.loadInfo(scope);
            });
            // this.ids = scope.id;
            // this.SchedulingPlanOBJ.surplusTicket = scope.surplusTicket;
            // this.SchedulingPlanOBJ.reservedTicket = scope.reservedTicket;
            break;
        }
      } else {
        switch (val) {
          case 6:
            this.popupNum = 6;
            this.workPopupShow = true;
            this.$nextTick(() => {
              this.$refs.popForm?.loadInfo(scope);
            });
            break;
        }
      }
    },
    // 调用操作的接口函数
    shiftIntercityAPIFun (data) {
      shiftIntercityAPI(data).then((res) => {
        if (res.code == "SUCCESS") {
          this.renderData();
          this.workPopupShow = false;
        }
      });
    },
    // 表格数据渲染
    renderData () {
      intercityPageAPI({
        ...this.formData,
        startTime: this.formData.startTime
          ? this.formData.startTime + " 00:00:00"
          : "",
        endTime: this.formData.endTime
          ? this.formData.endTime + " 23:59:59"
          : "",
      }).then((res) => {
        if ((res.code = "SUCCESS")) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 起终点下拉数据获取
    selectdataAPIFun () {
      startStationAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.startSelect = [];
          res.data.map((item, index) => {
            this.startSelect.push({ value: index, label: item });
          });
        }
      });
      endStationAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.endSelect = [];
          res.data.map((item, index) => {
            this.endSelect.push({ value: index, label: item });
          });
        }
      });
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.formData.currentPage = page;
      this.renderData();
    },
    // 当前条数改变
    onSizeChange (size) {
      this.formData.pageSize = size;
      this.formData.currentPage = 1;
      this.renderData();
    },
    // 弹窗事件
    popupFun (val, status, scope) {
      if (status == "query" || status == "edit") {
        this.SchedulingPlanOBJ = {
          status: status,
          scope: scope,
        };
      }
      this.workPopupShow = true;
      this.popupNum = val;
    },
    // 快速时间点击
    timeBtnFun (id) {
      this.timeBtnActive = id;
      this.formData.startTime = this.GMTToStr(new Date());
      switch (id) {
        case 0:
          this.formData.endTime = this.GMTToStr(new Date());
          break;
        case 1:
          this.formData.startTime = this.GMTToStr(moment().add(1, "d"));
          this.formData.endTime = this.GMTToStr(moment().add(1, "d"));
          break;
        case 2:
          this.formData.endTime = this.GMTToStr(moment().add(3, "d"));
          break;
        case 3:
          this.formData.endTime = this.GMTToStr(moment().add(7, "d"));
          break;
      }
      this.formData.currentPage = 1;
      this.renderData();
    },
    // 点击查询按钮
    search () {
      this.formData.currentPage = 1;
      this.renderData();
      this.timeBtnActive = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.timeBtnActive {
  background: #336ffe !important;
  color: #ffffff !important;
  border-radius: 6px;
}
.timeBtn {
  color: #336ffe;
  cursor: pointer;
  padding: 0 5px;
}
.prohibit {
  color: #9b9b9b !important;
}
.workforceManagement {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
  .ActionBar {
    background: #f7f8f9;
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 16px;
    flex-flow: wrap;
    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }
    .actionBar-box:after {
      content: '';
      flex: auto;
    }
    .actionBar-box {
      width: calc(25% - 13px);
      line-height: 32px;
      margin-right: 14px;
      margin-bottom: 16px;
      display: flex;
      .col-1 {
        width: 84px;
        display: inline-block;
      }
      .col-2 {
        max-width: 210px;
        width: calc(100% - 84px);
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>
